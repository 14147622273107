<template>
  <div>
    <!-- <div v-if="editor" style="margin-bottom: 1rem">
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        bold
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        italic
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        strike
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        code
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().unsetAllMarks().run()">
        clear marks
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
        paragraph
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        h1
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        h2
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        h3
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        h4
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
        h5
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
        h6
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        bullet list
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        ordered list
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
        code block
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        blockquote
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().setHardBreak().run()">
        hard break
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().undo().run()">
        undo
      </button>
      <button class="btn btn-default btn-sm" @click="editor.chain().focus().redo().run()">
        redo
      </button>
    </div> -->
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
// import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      // default: '',
    },
    focus_ts: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      editor: null,
    }
  },

   watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    },
    focus_ts(value) {
      this.editor.commands.focus('start')
      return
    }
  },

  mounted() {
    this.editor = new Editor({
//       content: `
// <p><em><strong>2019~2020년 투자 건수 : 9건</strong></em><br>벤처 펀드 투자 경력 : 80건+, 1,080억원+</p>
// - 2019~2020년 투자 건수 : 9건
// - 벤처 펀드 투자 경력 : 80건+, 1,080억원+
// <ul><li>나눔엔젤스</li><li>초기투자</li><li>문정</li></ul>
//       `,
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
      // placeholder: '내용을 입력 ...',
      extensions: [
        StarterKit,
        // Highlight,
        Typography,
        Placeholder,
        Link,
      ],
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.is-active {
  // font-weight: bold
  color: #007bff;
  &:hover {
    color: #007bff;
  }
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}

/* Basic editor styles */
.ProseMirror-focused {
  outline: 0;
}
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  a {
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
